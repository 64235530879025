<template>
  <section v-if="recommendations.length">
    <div
      class="container px-4 xl:px-0 text-xl lg:text-4xl font-bold mb-4 lg:mb-5 font-sf-pro text-gray-900"
    >
      Рекомендации
    </div>
    <t-list-events
      :events="listRecommendations"
      :totalEvents="totalRecomendations"
      @show-more="showMore"
      :loader="isLoading || load"
    />
  </section>
</template>

<script setup>
const { $get } = useApi()

const now = new Date()

const isPreviewSceleton = ref(true)

const listRecommendations = ref([])
const offset = ref(0)
const limit = ref(6)

const { data: totalRecomendations, pending: isTotalRecommendationsLoading } = await useAsyncData(
  `get total recommendations`,
  () =>
    $get(
      `/api/v1/recommendation/get/count?event.scheduleInfo.saleOpening_lte=${new Date(
        new Date(now)
      ).toISOString()}&event.scheduleInfo.saleEnding_gte=${new Date(new Date(now)).toISOString()}`
    ),
  {
    default: () => []
  }
)

const { data: recommendations, pending: isLoading } = await useAsyncData(
  `get recomended recommendations offset - ${offset.value} `,
  () =>
    $get(
      `/api/v1/recommendation?scheduleInfo.saleOpening_lte=${new Date(
        new Date(now)
      ).toISOString()}&scheduleInfo.saleEnding_gte=${new Date(
        new Date(now)
      ).toISOString()}&_sort=sort:asc&_skip=${offset.value}&_limit=${limit.value}`
    ),
  { watch: [offset], default: () => [] }
)

const load = ref(false)

function showMore() {
  offset.value += 6
}

const list = computed(() => {
  if (recommendations.value && recommendations.value.length) {
    return recommendations.value.map((recommendation) => recommendation.event)
  }
  return []
})

watch(
  list,
  () => {
    load.value = true
    listRecommendations.value = listRecommendations.value.concat(list.value)
    load.value = false
    isPreviewSceleton.value = false
  },
  { deep: false, immediate: true }
)
</script>
