<script setup lang="ts">
defineProps({
  events: {
    type: Array,
    default: function () {
      return []
    }
  },
  totalEvents: {
    type: Number,
    default: function () {
      return 0
    }
  },
  loader: {
    type: Boolean,
    default: () => false
  }
})

const emit = defineEmits('show-more')

function showMore(){
  emit('show-more')
}
</script>

<template>
  <div class="container px-4 xl:px-0">
    <t-card-list v-if="events.length" :totalEvents="totalEvents" @show-more="showMore" :data="events" :loader="loader" class="" />
    <t-empty v-else />
  </div>
</template>
